import { Injectable } from '@angular/core';
import { Labels, Permissions, ProjectMetaData } from '@sew/ui-components';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HeaderConfigService {
    private countryShortSubject = new BehaviorSubject<string>('');
    private cmsProjectSubject = new BehaviorSubject<string>('');
    private languageSubject = new BehaviorSubject<string>('');
    private initCompleteSubject = new ReplaySubject<boolean>(1);
    private labelsChangeSubject = new ReplaySubject<Labels>(1);
    private permissionsChangeSubject = new ReplaySubject<Permissions>(1);
    private projectMetaDataChangeSubject = new ReplaySubject<ProjectMetaData>(1);
    private loginTriggeredSubject = new BehaviorSubject<boolean>(false);
    private logoutTriggeredSubject = new BehaviorSubject<boolean>(false);

    /**
     * Emits the current `countryShort` initially and when changed.
     *
     * Example: `de`
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly countryShort = this.countryShortSubject.asObservable().pipe(
        distinctUntilChanged(),
        filter((value) => typeof value === 'string' && value.length > 0)
    );

    /**
     * Emits the current `cmsProject` initially and when changed.
     *
     * Example: `SEWDE`
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly cmsProject = this.cmsProjectSubject.asObservable().pipe(
        distinctUntilChanged(),
        filter((value) => typeof value === 'string' && value.length > 0)
    );

    /**
     * Emits the current `language` initially and when changed.
     *
     * Example: `de_DE`
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly language = this.languageSubject.asObservable().pipe(
        distinctUntilChanged(),
        filter((value) => typeof value === 'string' && value.length > 0)
    );

    /**
     * Emits `true` as soon as the header fires the event `initComplete`.
     *
     * Example: `true`
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly initComplete = this.initCompleteSubject.asObservable().pipe(distinctUntilChanged());

    /**
     * Emits the current `labels` initially and when changed.
     *
     * Example:
     * ```ts
     *  {
     *      CMS_AREA_SEARCH: "Umkreissuche"
     *      CMS_AUDIO_IS_EMPTY: "Der Audio Datensatz ist noch nicht vollständig"
     *      CMS_AUDIO_MINUTES: "Minuten"
     *      ...
     *  }
     * ```
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly labelsChange = this.labelsChangeSubject.asObservable().pipe(distinctUntilChanged());

    /**
     * Emits the current `projectMetaData` initially and when changed.
     *
     * Example:
     * ```ts
     *  {
     *      ...
     *      author: "SEW-EURODRIVE"
     *      cmp_id: "13337"
     *      ...
     *  }
     * ```
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly permissionsChange = this.permissionsChangeSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly projectMetaDataChange = this.projectMetaDataChangeSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly loginTriggered = this.loginTriggeredSubject.asObservable().pipe(distinctUntilChanged());

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly logoutTriggered = this.logoutTriggeredSubject.asObservable().pipe(distinctUntilChanged());

    /**
     * Returns the current `countryShort`
     *
     * @returns countryShort, example `de`
     */
    public getCountryShort() {
        return this.countryShortSubject.value;
    }

    public setCountryShort(countryShort: string) {
        this.countryShortSubject.next(countryShort);
    }

    /**
     * Returns the current `cmsProject`
     *
     * @returns cmsProject, example `SEWDE`
     */
    public getCmsProject() {
        return this.cmsProjectSubject.value;
    }

    public setCmsProject(cmsProject: string) {
        this.cmsProjectSubject.next(cmsProject);
    }

    /**
     * Returns the current `language`
     *
     * @returns language, example `de_DE`
     */
    public getLanguage() {
        return this.languageSubject.value;
    }

    public setLanguage(language: string) {
        this.languageSubject.next(language);
    }

    public triggerLogin() {
        this.loginTriggeredSubject.next(true);
    }

    public triggerLogout() {
        this.logoutTriggeredSubject.next(true);
    }

    public setInitComplete() {
        this.initCompleteSubject.next(true);
    }

    public setLabels(labels: Labels) {
        return this.labelsChangeSubject.next(labels);
    }

    public setPermissions(permissions: Permissions) {
        return this.permissionsChangeSubject.next(permissions);
    }

    public setProjectMetaData(projectMetaData: ProjectMetaData) {
        return this.projectMetaDataChangeSubject.next(projectMetaData);
    }

    public hasPermission(permission: string) {
        return this.permissionsChange.pipe(map((data) => data.some((item) => item.auth === permission)));
    }
}
