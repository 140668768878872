import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { delay, distinctUntilChanged, expand, map, Observable, ReplaySubject, tap } from 'rxjs';
import {
    arrayScreamingSnakeCaseToTitleCase,
    hasProperty,
    isArrayOfType,
    isTypeOfString,
} from '../../helpers/public-helpers';
import { ApiTokenEmitter, Tokens } from './os-api-auth.interface';

export const TOKEN_BASE_HREF = new InjectionToken<string>('OS_API:TOKEN_BASE_HREF');

/**
 * Service for fetching a x-csrf token for the user
 */

@Injectable({
    providedIn: 'root',
})
export class OsApiAuthService {
    private _xCsrfToken = new ReplaySubject<string>(1);
    private oAuthAccessToken$ = new ReplaySubject<Tokens>(1);
    private _oAuthAccessToken: Tokens = {};

    public constructor(
        private http: HttpClient,
        @Inject(TOKEN_BASE_HREF) private tokenURL: string // headerLoginService: HeaderLoginService
    ) {}

    /**
     * Fetch the token from the server on initial app load
     * and later after the refresh runs out
     */
    public fetchXCsrfToken() {
        // @TODO: Error handling
        return this.fetchXCsrfTokenResponse().pipe(
            expand((response) => this.fetchXCsrfTokenResponse().pipe(delay((response.body ?? 1800) * 900))),
            map((response) => response.headers.get('x-csrf-token') as string),
            tap((token) => this.setXCsrfToken(token))
        );
    }

    public clearSessionAfterOAuthLogout() {
        return this.http.get<number>('/os/api/oidc/logout', {
            withCredentials: true,
        });
    }

    public get allOAuthAccessToken(): Observable<Tokens> {
        // do not add distinctUntilChanged() because this will check
        // the object for reference and break the access token handling
        return this.oAuthAccessToken$.asObservable();
    }

    /**
     * Gets the permissions of the specified access token name.
     *
     * @param tokenName access token name
     * @returns permissions
     */
    public getPermissions(tokenName: string): Observable<string[]> {
        return this.allOAuthAccessToken.pipe(
            map((tokens) => {
                const token = tokens[tokenName];
                if (!token) {
                    console.warn(`Token ${tokenName} not found`);

                    return [];
                }

                const payloadEncoded = atob(token.split('.')[1]);
                const payload = JSON.parse(payloadEncoded);

                if (!hasProperty<{ permissions: any }, string>(payload, 'permissions')) {
                    console.warn('Permissions not found');

                    return [];
                }

                const permissions = payload.permissions;

                if (!isArrayOfType<string>(permissions, isTypeOfString)) {
                    console.warn('Permissions malformed');

                    return [];
                }

                return arrayScreamingSnakeCaseToTitleCase(permissions);
            })
        );
    }

    public setOAuthAccessToken(accessToken: ApiTokenEmitter) {
        this._oAuthAccessToken[accessToken.api] = accessToken.token;
        this.oAuthAccessToken$.next(this._oAuthAccessToken);
    }

    private fetchXCsrfTokenResponse() {
        return this.http.get<number>(this.tokenURL, {
            headers: new HttpHeaders({
                'x-csrf-token': 'fetch',
            }),
            withCredentials: true,
            observe: 'response',
        });
    }

    public get xCsrfToken(): Observable<string> {
        return this._xCsrfToken.asObservable().pipe(distinctUntilChanged());
    }

    private setXCsrfToken(newToken: string) {
        this._xCsrfToken.next(newToken);
    }
}
