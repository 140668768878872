import { Observable, OperatorFunction, UnaryFunction, filter, pipe } from 'rxjs';
import { ValueRange } from '../../interfaces/core.interface';

/**
 * Checks wheter a variable of a certain type is defined.
 *
 * @param value input asserted to be defined
 * @returns true if value is not undefined, else false
 */
export const isDefined = <T>(value?: any): value is T =>
    (value as any) !== undefined && (value as any) !== null;

/**
 * Filters all nullish values output by observables and guarantees that the type of the values output by the observable is not nullish.
 *
 * @returns true if value is not nullish, else false
 */
export const filterNullishEmittedByObservable = <T>(): UnaryFunction<
    Observable<T | null | undefined>,
    Observable<T>
> => pipe(filter((x) => x !== null && x !== undefined) as OperatorFunction<T | null | undefined, T>);

/**
 * Checks if variable is of type `string`.
 *
 * @param input variable with unknown type
 * @returns variable is type string
 */
export const isTypeOfString = (input: any): input is string =>
    input !== undefined && input !== null && typeof input === 'string';

/**
 * Checks if variable is of type `number`.
 *
 * @param input variable with unknown type
 * @returns variable is type number
 */
export const isTypeOfNumber = (input: any): input is number =>
    input !== undefined && input !== null && typeof input === 'number';

/**
 * Checks if variable is of type `ValueRange`.
 *
 * @param input variable with unknown type
 * @returns variable is type `ValueRange`
 */
export const isTypeOfValueRange = <T>(input: any): input is ValueRange<T> =>
    input !== undefined &&
    input !== null &&
    typeof input === 'object' &&
    input.hasOwnProperty('low') &&
    input.hasOwnProperty('high');

/**
 * Checks if variable is an Array.
 *
 * @param input variable with unknown type
 * @returns variable is an `Array`
 */
export const isArray = <T>(input: any): input is Array<T> =>
    input !== undefined && input !== null && Array.isArray(input);

/**
 * Checks if variable is an Array of a specific type.
 *
 * @param input variable with unknown type
 * @param validator validator function for the specific type
 * @returns variable is an `Array` of a specific type
 */
export const isArrayOfType = <T>(input: any, validator: (item: any) => item is T): input is Array<T> =>
    input !== undefined && input !== null && Array.isArray(input) && input.every(validator);

/**
 * Checks if an object has a specific property.
 *
 * @param obj The object to check.
 * @param key The property key to check for.
 * @returns True if the object has the specified property, else false.
 */
export const hasProperty = <T extends Record<string, unknown>, K extends string>(
    obj: T,
    key: K
): obj is T & { [P in K]: unknown } =>
    obj !== undefined && obj !== null && typeof obj === 'object' && obj.hasOwnProperty(key);
