<sew-dialog ws close-button="{{ false }}" [attr.open]="displayCancelDialog ? true : null" size="xs">
    <p>
        {{ labels?.CMS_MARKET_REGULATION_CANCEL_DIALOG_TEXT }}
    </p>
    <sew-ws-button slot="button">
        <button
            [innerText]="labels?.CMS_MARKET_REGULATION_CANCEL_DIALOG_CANCEL"
            (click)="cancelFilterChange()"
        ></button>
    </sew-ws-button>
    <sew-ws-button slot="button" hierarchy="primary">
        <button
            [innerText]="labels?.CMS_MARKET_REGULATION_CANCEL_DIALOG_CONTINUE"
            (click)="confirmFilterChange()"
        ></button>
    </sew-ws-button>
</sew-dialog>

<div class="page-wrapper">
    <reg-preview-dashboard *ngIf="isPreview" class="filter"> </reg-preview-dashboard>

    <ng-container *ngIf="currentPage === 'landingPage'; else resultHeadline">
        <sew-headline level="1">{{ labels?.CMS_MARKET_REGULATION_INTRO_HEADLINE }}</sew-headline>
        <sew-ws-paragraph size="2">
            {{ labels?.CMS_MARKET_REGULATION_INTRO_TEXT }}
        </sew-ws-paragraph>
    </ng-container>
    <ng-template #resultHeadline>
        <sew-headline level="1">{{ labels?.CMS_MARKET_REGULATION_FILTER_HEADLINE }}</sew-headline>
    </ng-template>
    <div [ngClass]="currentPage === 'landingPage' ? 'container container-reverse' : ''">
        <div class="container filter">
            <sew-combobox
                #countryDropdown
                ws
                (mouseup)="scrollToFilterTop(countryDropdown)"
                (touchend)="scrollToFilterTop(countryDropdown)"
                (itemSelected)="countrySelected($event)"
                [placeholder]="labels?.CMS_MARKET_REGULATION_FILTER_COUNTRY"
                [comboboxId]="'country'"
            >
                <select
                    title="{{ labels?.CMS_MARKET_REGULATION_FILTER_COUNTRY }}"
                    [ngModel]="selectedCountryId"
                >
                    <option></option>
                    <option *ngFor="let country of countries | dropdownInput | async" [value]="country.value">
                        {{ country.name }}
                    </option>
                </select>
            </sew-combobox>

            <sew-combobox
                ws
                (mouseup)="scrollToFilterTop(countryDropdown)"
                (touchend)="scrollToFilterTop(countryDropdown)"
                (itemSelected)="componentSelected($event)"
                [disabled]="!selectedCountryId ? true : false"
                [placeholder]="labels?.CMS_MARKET_REGULATION_FILTER_COMPONENT"
                [comboboxId]="'component'"
            >
                <select #componentDropdown title="{{ labels?.CMS_MARKET_REGULATION_FILTER_COMPONENT }}">
                    <option></option>
                    <option
                        *ngFor="let component of components | dropdownInput | async"
                        [value]="component.value"
                        [selected]="component.value === selectedComponentId + '' ? true : false"
                    >
                        {{ component.name }}
                    </option>
                </select>
            </sew-combobox>

            <sew-combobox
                ws
                (mouseup)="scrollToFilterTop(countryDropdown)"
                (touchend)="scrollToFilterTop(countryDropdown)"
                (itemSelected)="regulationSelected($event)"
                [disabled]="!selectedComponentId || !selectedCountryId ? true : false"
                [placeholder]="labels?.CMS_MARKET_REGULATION_FILTER_REGULATION"
                [comboboxId]="'regulation'"
            >
                <select #regulationsDropdown title="{{ labels?.CMS_MARKET_REGULATION_FILTER_REGULATION }}">
                    <option></option>
                    <option
                        *ngFor="let regulation of regulations | dropdownInput | async"
                        [value]="regulation.value"
                        [selected]="regulation.value === selectedRegulationId + '' ? true : false"
                    >
                        {{ regulation.name }}
                    </option>
                </select>
            </sew-combobox>

            <sew-ws-button>
                <button
                    class="slotted-result-button"
                    [disabled]="!selectedRegulationId || !selectedComponentId || !selectedCountryId"
                    (click)="handleButtonClick()"
                >
                    {{
                        currentPage === 'landingPage'
                            ? labels?.CMS_MARKET_REGULATION_SHOW_RESULTS
                            : labels?.CMS_MARKET_REGULATION_BACK_TO_MAP
                    }}
                </button>
            </sew-ws-button>
        </div>
        <div #routerOutletWrapper>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<reg-energy [labels]="labels"></reg-energy>
