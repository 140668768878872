import { PRODUCTION_ENVIRONMENT } from '@os-components';
import { authProd } from './environment-variables';

export const environment = {
    ...PRODUCTION_ENVIRONMENT,
    baseURLs: {
        ...PRODUCTION_ENVIRONMENT.baseURLs,
        href: '/international-regulations/',
        api: {
            ...PRODUCTION_ENVIRONMENT.baseURLs.api,
            domain: '',
            path: '/os/api/reg',
        },
    },
    auth: authProd,
    preview: false,
};
