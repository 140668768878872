import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { User } from './header-login.interface';

@Injectable({
    providedIn: 'root',
})
export class HeaderLoginService {
    /**
     * Behavior subject emitting the current user object.
     * Is set to undefined until the first value of the header is emitted.
     * Is set to null, if no user is logged in.
     */
    private userSubject = new BehaviorSubject<User | null | undefined>(undefined);

    public get loggedIn(): Observable<boolean> {
        return this.user.pipe(
            map((user) => user !== null && user !== undefined),
            distinctUntilChanged()
        );
    }

    public get employee(): Observable<boolean> {
        return this.user.pipe(
            map(
                (user: User | null | undefined) =>
                    user !== null && user !== undefined && (user.employee === 'X' || !!user.isEmployee)
            ),
            distinctUntilChanged()
        );
    }

    public setUser(user: User | null) {
        this.userSubject.next(user);
    }

    public get user(): Observable<User | null | undefined> {
        return this.userSubject.asObservable().pipe(distinctUntilChanged());
    }

    public getUser(): User | null | undefined {
        return this.userSubject.value;
    }
}
