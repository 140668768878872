import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { filter, Observable, race, Subject, switchMap, take, takeUntil, timer } from 'rxjs';
import { HeaderConfigService, HeaderLoginService } from '../public-services';
import { OsApiAuthRealm, OsApiAuthRealms, Tokens } from './os-api-auth.interface';
import { OsApiAuthService } from './os-api-auth.service';

export const API_AUTH_REALMS = new InjectionToken<OsApiAuthRealms>('OS_API:API_BASE_URL');

/**
 * Interceptor to handle authentication tokens/headers for certain HTTP requests
 */
@Injectable({
    providedIn: null,
})
export class OsApiAuthInterceptor implements HttpInterceptor, OnDestroy {
    private xCsrfToken = '';
    private oAuthAccessTokens: Tokens = {};
    private isLoggedIn?: boolean = undefined;
    private destroySubject = new Subject<void>();

    public constructor(
        @Inject(API_AUTH_REALMS) private apiRealms: OsApiAuthRealms,
        private authService: OsApiAuthService,
        private loginService: HeaderLoginService,
        private headerConfigService: HeaderConfigService
    ) {
        this.authService.xCsrfToken
            .pipe(takeUntil(this.destroySubject))
            .subscribe((token) => (this.xCsrfToken = token));
        this.authService.allOAuthAccessToken
            .pipe(takeUntil(this.destroySubject))
            .subscribe((tokens) => (this.oAuthAccessTokens = tokens));
        this.loginService.loggedIn
            .pipe(takeUntil(this.destroySubject))
            .subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn));
    }

    public ngOnDestroy(): void {
        this.destroySubject.next();
        this.destroySubject.complete();
    }

    public intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        const apiRealm = this.apiRealms.find((realm) => req.url.startsWith(realm.path));
        if (!apiRealm) {
            return next.handle(req);
        }

        return this.realmIntercept(apiRealm, req, next);
    }

    private realmIntercept<T>(
        realm: OsApiAuthRealm,
        req: HttpRequest<T>,
        next: HttpHandler
    ): Observable<HttpEvent<T>> {
        const newRequest = {
            url: `${realm.apiUrl}${req.url.replace(realm.path, '')}`,
            withCredentials: realm.withCredentials,
            setHeaders: realm.headers,
        };

        const getAccessTokenByApiName = (tokens: Tokens) =>
            realm.accessTokenApiName !== undefined && tokens[realm.accessTokenApiName];

        /**
         *  Adds Bearer Token the the new request if one exists for the API.
         *
         * @param request request to be cloned
         * @returns new request
         */
        const cloneRequestWithBearerToken = (request: HttpRequest<unknown>) => {
            const headers = {
                ...newRequest.setHeaders,
                ...(realm.accessTokenApiName &&
                    this.oAuthAccessTokens[realm.accessTokenApiName] && {
                        Authorization: `Bearer ${this.oAuthAccessTokens[realm.accessTokenApiName]}`,
                    }),
                ...(request.method !== 'GET' && realm.useXCsrfToken && { 'x-csrf-token': this.xCsrfToken }),
            };

            return request.clone({
                ...newRequest,
                setHeaders: headers,
            });
        };

        /**
         * Add access token/ bearer token for Microsoft B2C Authentication.
         *
         * TODO: customize behaviour `retriggerAfterLoginChange`
         */
        if (realm.accessTokenApiName) {
            return this.headerConfigService.initComplete.pipe(
                switchMap(() => {
                    if (!this.isLoggedIn) {
                        return next.handle(req.clone(newRequest));
                    } else if (!!getAccessTokenByApiName(this.oAuthAccessTokens)) {
                        return next.handle(cloneRequestWithBearerToken(req));
                    } else {
                        return race(
                            this.authService.allOAuthAccessToken.pipe(
                                filter((tokens) => !!getAccessTokenByApiName(tokens)),
                                take(1),
                                switchMap(() => next.handle(cloneRequestWithBearerToken(req)))
                            ),
                            timer(2000).pipe(switchMap(() => next.handle(req.clone(newRequest))))
                        );
                    }
                })
            );
        }

        return this.legacyIntercept(newRequest, realm, req, next);
    }

    private legacyIntercept<T>(
        newRequest: {
            url: string;
            withCredentials: boolean;
            setHeaders?: Record<string, string>;
        },
        realm: OsApiAuthRealm,
        req: HttpRequest<T>,
        next: HttpHandler
    ) {
        const cloneRequestWithCsrfToken = (request: HttpRequest<unknown>) =>
            request.clone({
                ...newRequest,
                setHeaders: {
                    ...newRequest.setHeaders,
                    ...(realm.useXCsrfToken && { 'x-csrf-token': this.xCsrfToken }),
                },
            });
        const getRequestClone = () => {
            const isGet = req.method === 'GET';
            const handle = isGet
                ? next.handle(req.clone(newRequest))
                : next.handle(cloneRequestWithCsrfToken(req));

            // TODO: implement generic solution for basket
            if (!realm.retriggerAfterLoginChange || newRequest.url.includes('/basket')) {
                return handle;
            }

            return (isGet && realm.withCredentials) ||
                (!isGet && (realm.withCredentials || realm.useXCsrfToken))
                ? this.loginService.loggedIn.pipe(switchMap(() => handle))
                : handle;
        };

        return getRequestClone();
    }
}
