import { OS_API_TOKEN_ENDPOINT } from '../lib/helpers/public-helpers';

export const PRODUCTION_ENVIRONMENT = {
    production: true,
    useCMP: true,
    useOauth: false,
    useMockServer: false,
    useMopinion: false,
    defaultLang: 'en_US',
    cms: 'debrucmsp',
    baseURLs: {
        api: {
            international: 'https://api.sew-eurodrive.com',
            translation: '/os/api/service/translate',
            token: OS_API_TOKEN_ENDPOINT,
            workbench: 'https://api.sew-eurodrive.com/workbenchwebapi/api',
            os: '/os',
        },
    },
    caas: {
        baseUrl: 'https://caas.sew-eurodrive.com',
    },
    apiGateway: {
        dmz: {
            baseUrl: 'https://apigateway-external.sew-eurodrive.com/gateway',
        },
    },
    stage: 'PROD',
};
