interface OAuthTokenScope {
    clientId: string;
    policy: string;
    scopes: {
        api: string;
        scopes: string[];
    }[];
}

export const authDev: OAuthTokenScope = {
    clientId: 'b96ccfbb-3a86-4617-9f89-ab016c76771d',
    policy: 'B2C_1A_SEW_SUSI_HRD',
    scopes: [
        {
            api: 'WZ',
            scopes: ['openid', 'https://login.sew-d.com/c636b7c3-d751-4420-ba67-cba921820da0/FullAccess'],
        },
    ],
};
export const authQa: OAuthTokenScope = {
    clientId: '',
    policy: 'B2C_1A_SEW_SUSI_HRD',
    scopes: [
        {
            api: 'WZ',
            scopes: ['openid'],
        },
    ],
};

// TODO Ändern mit richtige wenn es gibt prod auth scope
export const authProd: OAuthTokenScope = {
    clientId: '',
    policy: 'B2C_1A_SEW_SUSI_HRD',
    scopes: [
        {
            api: 'WZ',
            scopes: ['openid'],
        },
    ],
};

// export const href = '/os/basket/';
// export const path = '/os/api/basket';
