import { Directive, HostListener, Optional } from '@angular/core';
import { Labels, Permissions, ProjectMetaData, Tracking } from '@sew/ui-components';
import {
    HeaderConfigService,
    HeaderLoginService,
    OsApiAuthService,
    TrackingService,
    User,
} from '../../services/public-services';

interface CustomEvent<T> {
    detail: T;
}

interface ApiTokenEmitter {
    api: string;
    token: string;
}

interface SewWsHeaderCustomEvent<T> extends CustomEvent<T> {
    detail: T;
    target: HTMLSewWsHeaderElement;
}

@Directive({
    selector: '[oscHeaderEvents]',
})
export class HeaderEventsDirective {
    public constructor(
        private configService: HeaderConfigService,
        private loginService: HeaderLoginService,
        private authService: OsApiAuthService,
        @Optional() private trackingService: TrackingService
    ) {}

    @HostListener('languageSwitch', ['$event'])
    public languageSwitch(event: CustomEvent<string>) {
        this.configService.setLanguage(event.detail);
    }

    @HostListener('countryShort', ['$event'])
    public countryShort(event: CustomEvent<string>) {
        this.configService.setCountryShort(event.detail);
    }

    @HostListener('cmsProject', ['$event'])
    public cmsProject(event: CustomEvent<string>) {
        this.configService.setCmsProject(event.detail);
    }

    @HostListener('userChange', ['$event'])
    public userChange(event: CustomEvent<User>) {
        this.loginService.setUser(event.detail);
    }

    @HostListener('initComplete', ['$event'])
    public initComplete() {
        this.configService.setInitComplete();
    }

    @HostListener('labelsChange', ['$event'])
    public labelsChange(event: CustomEvent<Labels>) {
        this.configService.setLabels(event.detail);
    }

    @HostListener('projectMetaDataChange', ['$event'])
    public projectMetaDataChange(event: CustomEvent<ProjectMetaData>) {
        this.configService.setProjectMetaData(event.detail);
    }

    @HostListener('permissionChange', ['$event'])
    public userPermissionChange(event: CustomEvent<Permissions>) {
        this.configService.setPermissions(event.detail);
    }

    @HostListener('getTrackingService', ['$event'])
    public getTrackingService(event: CustomEvent<Tracking>) {
        if (this.trackingService !== null) {
            this.trackingService.setTracker(event.detail);
        }
    }

    @HostListener('loginRequested', ['$event'])
    public loginTriggered() {
        sessionStorage.setItem('loginRequested', window.location.href);
        this.configService.triggerLogin();
    }

    @HostListener('logoutRequested', ['$event'])
    public logoutTriggered() {
        this.configService.triggerLogout();
    }

    @HostListener('accessToken', ['$event'])
    public setAccessTokens($event: SewWsHeaderCustomEvent<ApiTokenEmitter | undefined>) {
        if (!$event?.detail) {
            return;
        } else {
            this.authService.setOAuthAccessToken($event.detail);
        }
    }
}
