import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Labels } from '@sew/ui-components';
import { NEVER, race } from 'rxjs';
import { catchError, startWith, timeout } from 'rxjs/operators';
import {
    BaseComponent,
    HeaderConfigService,
    TrackingService,
    getTranslationLanguageKey,
    getTwoLetterLanguageCode,
} from '../../../lib-os-components/src/public-api';
import { environment } from '../environments/environment';
import { CurrentPage } from './features/landing-page/landing-page.component';
import { tracking } from './helpers/tracking';

@Component({
    selector: 'reg-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
    public currentPage: CurrentPage = 'landingPage';
    public currentCountry?: string;
    public currentLanguage?: string;
    public useCMP = environment.useCMP;
    public authConfig = environment.auth;
    public stage = environment.stage;

    public constructor(
        public sewConfigService: HeaderConfigService,
        public translateService: TranslateService,
        private router: Router,
        private titleService: Title,
        private trackingService: TrackingService,
        private meta: Meta,
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute
    ) {
        super(sewConfigService, translateService, environment);
    }

    public ngOnInit(): void {
        // set global js variable for etracker
        (window as any).et_areas = 'International Regulations';

        this.route.queryParams.subscribe((params) => {
            const countryParam: string = params.country;
            if (countryParam) {
                this.currentCountry = countryParam.toLowerCase();
            }
            const langaugeParam: string = params.language;
            if (langaugeParam) {
                this.currentLanguage = langaugeParam;
            }
        });

        this.baseURL = environment.baseURLs.api.domain.length
            ? environment.baseURLs.api.domain
            : `${window.location.protocol}//${window.location.hostname}`;

        this.caasBaseUrl = environment.caas.baseUrl;
        this.apiBaseUrl = environment.baseURLs.api.international;

        this.translateService.onLangChange.subscribe(() => {
            this.setFooterLinks();
        });

        this.languageObservable.subscribe((lang: string) => {
            this.setLanguage(lang);
        });

        const labelsObs = this.sewConfigService.labelsChange;
        race(
            labelsObs,
            NEVER.pipe(
                timeout(5000),
                catchError(() => labelsObs.pipe(startWith('Lexfinder')))
            )
        ).subscribe((labels: Labels | string) => {
            if (typeof labels === 'string') {
                this.titleService.setTitle(labels);
            } else {
                this.titleService.setTitle(labels.CMS_MARKET_REGULATION_PAGE_TITLE);
                this.meta.updateTag({
                    name: 'description',
                    content: labels.CMS_MARKET_REGULATION_PAGE_DESCRIPTION,
                });
            }
            this.trackingService.trackEvent(tracking.step.init);
        });

        this.router.events.subscribe((route) => {
            if (route instanceof NavigationEnd) {
                const url = route.urlAfterRedirects;
                if (url === '/') {
                    this.currentPage = 'landingPage';
                } else if (url.includes('/result')) {
                    this.currentPage = 'resultPage';
                }
            }
        });

        if (environment.preview) {
            this.initOCM();
        }
    }

    private initOCM(): void {
        const head = document.getElementsByTagName('head')[0] || document.documentElement;
        const script1 = document.createElement('script');
        script1.src = `https://${environment.cms}/fs5webedit/live/api.jsp`;
        script1.setAttribute(
            'data-firstspirit-origin',
            `https://${environment.cms}/international-regulations/`
        );
        head.appendChild(script1);
    }

    private setLanguage(language: string) {
        const languageKey = getTranslationLanguageKey(language);
        this.language = language.toUpperCase();
        this.translateService.use(languageKey);
        this.document.documentElement.lang = getTwoLetterLanguageCode(languageKey);
    }
}
